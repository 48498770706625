<template>
    <v-col cols="2">
        <img
            alt="Esenda logo"
            :src="esendaLogoPng"
            class="esenda-logo my-0 py-0 ml-4"
        />
    </v-col>
    <v-spacer></v-spacer>
    <logged-in-user
        v-if="isAuthenticated"
        :user-picture="userPicture"
        :user-name="userName"
    ></logged-in-user>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { computed } from "vue";
import loggedInUser from "../components/group/loggedInUser.vue";
import esendaLogoPng from "../assets/logos/esenda-black-logo.png";

// gets current user
const { user } = useAuth0();
const { isAuthenticated } = useAuth0();

// get user details
const userPicture = computed(() => {
    return user.value !== undefined ? user.value.picture : "";
});
const userName = computed(() => {
    return user.value !== undefined ? user.value.name : "";
});
</script>
