import axios from "axios";
import { IpInfoResponse } from "../../helpers/interfaces/shared";

export const getIpInfo = async (): Promise<IpInfoResponse> => {
    /**
     * Gets the user's country code based on the IP address.
     * Uses the ipinfo.io API, which is limited to 50,000 requests per month in the free tier.
     */
    const ipInfoUrl =
        "https://ipinfo.io/json?token=" + import.meta.env.VITE_IPINFO_TOKEN;

    const config = {
        url: ipInfoUrl,
        method: "GET",
    };

    try {
        const response = await axios(config);
        const data: IpInfoResponse = response.data;
        return data;
    } catch (error) {
        return {
            data: null,
        };
    }
};
