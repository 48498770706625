import { timeZoneCityToCountryMapping } from "../constants/timezoneCityToCountryMapping";
import { getIpInfo } from "../apis/ipInfo/index";
import { IpInfoResponse } from "./interfaces/shared";

export const getCountryCodeFromTimezone = () => {
    /**
     * Gets the user's country code based on the timezone.
     * Intl.DateTimeFormat().resolvedOptions().timeZone gives the default timezone of the runtime/system
     * @return {[string]}
     */

    let userCity;
    let userCountry;
    let userTimeZone;

    if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const tzArr = userTimeZone.split("/");
        userCity = tzArr[tzArr.length - 1];
        userCountry = timeZoneCityToCountryMapping[userCity];
    }

    return userCountry;
};

export const getCountryCodeFromIPAddress = async () => {
    /**
     * Gets the user's country code based on the IP address.
     * Uses the ipinfo.io API, which is limited to 50,000 requests per month in the free tier.
     * @return {[string]}
     */

    let countryCode = "";

    const data: IpInfoResponse = await getIpInfo();

    if (data !== null) {
        countryCode = "country" in data ? (data.country as string) : "";
    }

    return countryCode;
};
