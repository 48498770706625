<template>
    <v-snackbar
        v-model="model"
        :color="alertColor"
        data-cy="alert"
        :timeout="3000"
    >
        <span v-if="internationalisedMessage" class="white-text">{{
            t(alertMessage)
        }}</span>
        <span v-else class="white-text">{{ alertMessage }}</span>
        <template #actions>
            <img
                :src="closeIconSvg"
                class="pointer-cursor mr-1"
                @click="closeAlert"
            />
        </template>
    </v-snackbar>
</template>

<script setup lang="ts">
import { computed } from "vue";
import closeIconSvg from "../../assets/icons/20x20/ic20-close.svg";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
    modelValue: boolean;
    alertMessage?: string;
    alertColor?: string;
    internationalisedMessage?: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const model = computed({
    get: () => props.modelValue,
    set: (value) => {
        emit("update:modelValue", value);
    },
});

const closeAlert = () => {
    emit("update:modelValue", false);
};
</script>
