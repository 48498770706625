<template>
    <div class="menu-container">
        <v-menu color="background" class="ma-0 pa-0">
            <template #activator="{ props }">
                <v-btn
                    icon="mdi-account"
                    size="32"
                    color="background"
                    elevation="0"
                    v-bind="props"
                ></v-btn>
            </template>
            <v-list
                class="user-menu py-0"
                color="background"
                data-cy="user-menu"
            >
                <v-list-item data-cy="username" class="user-menu-item">
                    <template #prepend>
                        <v-avatar size="30" class="mr-2 user-picture">
                            <v-img
                                :src="userPicture"
                                alt="account-picture"
                            ></v-img>
                        </v-avatar>
                    </template>
                    <v-list-item-title class="mr-2 left-text-align">{{
                        userName
                    }}</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                    data-cy="logout-button"
                    class="user-menu-item"
                    @click="handleLogout()"
                >
                    <template #prepend>
                        <v-icon color="dark" size="25" class="mr-2"
                            >mdi-logout-variant</v-icon
                        >
                    </template>
                    <v-list-item-title class="mr-2 left-text-align"
                        >Logout</v-list-item-title
                    >
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";

defineProps<{
    userPicture?: string;
    userName?: string;
}>();

const { logout } = useAuth0();
// logs user out
const handleLogout = () => {
    const logoutURL = window.origin + "/auth0/logout/redirect";
    logout({
        logoutParams: {
            returnTo: logoutURL,
        },
    });
};
</script>
