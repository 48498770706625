// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

const lightTheme = {
    dark: false,
    colors: {
        primary: "#007AFF",
        secondary: "#38DFF0",
        tertiary: "#D538ee",
        white: "#FFFFFF",
        dark: "#010109",
        "gradient-dark": "#01084e",
        "slate-grey": "#6A7289",
        background: "#F9FAFB",
        success: "#009D58",
        warning: "#FFC65E",
        error: "#B1002F",
        disabled: "#EEEEEE",
    },
};

const vuetify = createVuetify({
    theme: {
        defaultTheme: "lightTheme",
        themes: {
            lightTheme,
        },
    },
    components,
    directives,
});

export default vuetify;
