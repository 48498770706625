/**
 *  Detecting variable types helpers. Can be imported in any component.
 */

export const isArray = function (arr) {
    /**
     * Checks if given param is an array
     * @param  {[any]} arr
     * @return {[boolean]}
     */
    return Array.isArray(arr);
};

export const isObject = function (obj) {
    /**
     * Checks if given param is an object
     * @param  {[any]} obj
     * @return {[boolean]}
     */
    return obj === Object(obj) && !isArray(obj) && typeof obj !== "function";
};

export const hasValidProperty = (obj: any, key: string) => {
    /**
     * Checks if a given object has a given key and if the
     * property of the key is not null
     * @param {[any]} any
     * @param {[string]} key
     * @returns {[boolean]}
     */
    if ([undefined, "undefined", null, "null"].includes(obj)) {
        return false;
    } else {
        return obj.hasOwnProperty(key) && obj[key] !== null;
    }
};
